import styled from 'styled-components';

const Speaker = styled.a`
  width: 200px;
  text-decoration: none;
  color: white;
  margin-bottom: 32px;
  position: relative;
  font-family: Orbitron;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  ${props => props.center && `margin: 0 auto;`}
  img {
    margin-bottom: 0;
    width: 200px;
    height: 200px;
    @media (max-width: 550px) {
      height: 150px;
    }
  }
  @media (max-width: 550px) {
    width: 150px;
  }
`;

export default Speaker;
