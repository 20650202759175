import React from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';

// styled
import Superscript from '../components/styled/Superscript';
import Section from '../components/styled/Section';
import Container from '../components/styled/Container';
import Wrapper from '../components/styled/Wrapper';
import Row from '../components/styled/Row';
import Column from '../components/styled/Column';
import Button from '../components/styled/Button';
import SpeakerName from '../components/styled/SpeakerName';
import Speaker from '../components/styled/Speaker';

// assets
import adam from '../assets/speakers2019/adam.jpg';
import carolina from '../assets/speakers2019/carolina.jpg';
import chris from '../assets/speakers2019/chris.jpg';
import clare from '../assets/speakers2019/clare.jpg';
import colin from '../assets/speakers2019/colin.png';
import isaac from '../assets/speakers2019/isaac.jpg';
import james from '../assets/speakers2019/james.jpg';
import jason from '../assets/speakers2019/jason.jpg';
import josh from '../assets/speakers2019/josh.jpg';
import michael from '../assets/speakers2019/michael.jpg';
import nancy from '../assets/speakers2019/nancy.jpg';
import ryan from '../assets/speakers2019/ryan.jpg';

class ProposeTalk extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Speakers" />
        <Nav selected="/speakers" />
        <Header />
        <Section noPaddingTop>
          <Wrapper>
            <Superscript center>Speakers</Superscript>
            <Superscript small center>
              Keynote
            </Superscript>
            <Row paddingTop id="james">
              <Column shrink>
                <Speaker href="https://www.jamesshore.com/" center>
                  <img src={james} />
                </Speaker>
              </Column>
              <Column>
                <a href="https://www.jamesshore.com/" className="noUnderline">
                  <SpeakerName large company="">
                    James Shore
                  </SpeakerName>
                </a>
                <h1>Thinking in tests</h1>
                <p>
                  Of course we want our code to work. That’s a given. And of
                  course we want our code to be easy to write, to be fast and
                  elegant, to sing when we put hands to keyboard. And to be
                  worthwhile: to make a difference, to simplify a life, or maybe
                  just make a buck.
                </p>
                <p>
                  Tests make it all possible. They don’t make it happen, but
                  they make it possible.
                </p>
                <p>
                  So you’ve been writing tests. You make code that works. Let’s
                  talk about what’s next.
                </p>
                <p>
                  <a href="https://www.jamesshore.com/">James Shore</a> teaches,
                  writes, and consults on Agile development processes with an
                  emphasis on technical excellence. He test-drove his first web
                  application in 2000 and hasn’t stopped since. He is a
                  recipient of the Agile Alliance's Gordon Pask Award for
                  Contributions to Agile Practice, co-author of{' '}
                  <a href="http://shop.oreilly.com/product/9780596527679.do">
                    The Art of Agile Development (O'Reilly)
                  </a>
                  , host of{' '}
                  <a href="https://www.letscodejavascript.com/">
                    Let's Code: Test-Driven JavaScript
                  </a>
                  , and co-creator of the{' '}
                  <a href="https://martinfowler.com/articles/agileFluency.html">
                    Agile Fluency™ Model
                  </a>
                  . InfoQ has named him one of the “most influential people in
                  Agile.”
                </p>
              </Column>
            </Row>

            <Superscript small center>
              All Talks
            </Superscript>
            <Row paddingTop id="jason">
              <Column shrink>
                <Speaker href="https://twitter.com/palmerj3" center>
                  <img src={jason} />
                </Speaker>
              </Column>
              <Column>
                <a href="https://twitter.com/palmerj3" className="noUnderline">
                  <SpeakerName large company="Spotify">
                    Jason Palmer
                  </SpeakerName>
                </a>
                <h1>
                  Test flakiness - methods for identifying and dealing with
                  flaky tests
                </h1>
                <p>
                  Test flakiness is a problem ALL engineering teams face and is
                  the most common thing that prevents continuous delivery. This
                  talk will help you learn how to identify flaky tests, deal
                  with them, and achieve confident continuous delivery.
                </p>
                <p>
                  Jason Palmer is technical product manager for web
                  infrastructure and test infrastructure teams at Spotify. He
                  has been at Spotify for 7.5 years mostly as a web engineer. He
                  is also part of the Jest core team and creator/maintainer of
                  jest-junit.
                </p>
              </Column>
            </Row>
            <Row paddingTop id="adam">
              <Column shrink>
                <Speaker href="https://twitter.com/agarcher" center>
                  <img src={adam} />
                </Speaker>
              </Column>
              <Column>
                <a href="https://twitter.com/agarcher" className="noUnderline">
                  <SpeakerName large company="Shopify">
                    Adam Archer
                  </SpeakerName>
                </a>
                <h1>
                  Scaling R&amp;D to thousands of people without dedicated QA
                </h1>
                <p>
                  At Shopify, we believe that QA is part of a software
                  developer’s job. As a result, we don’t have a QA team. In this
                  talk, we will present how we organize, what processes we have
                  put in place, and what tools we have built to scale an R&D
                  organization to thousands of people without having a single
                  dedicated tester.
                </p>
                <p>
                  Adam is a development manager at Shopify and is currently
                  focused on the backend for their retail products. He has
                  dabbled in frontend and backend over the course of his career.
                  He believes that being an effective communicator is an
                  engineer's most powerful and least recognized asset.
                </p>
              </Column>
            </Row>
            <Row paddingTop id="colin">
              <Column shrink>
                <Speaker href="https://twitter.com/cjihrig" center>
                  <img src={colin} />
                </Speaker>
              </Column>
              <Column>
                <a href="https://twitter.com/cjihrig" className="noUnderline">
                  <SpeakerName large company="Joyent">
                    Colin Ihrig
                  </SpeakerName>
                </a>
                <h1>Tests > types</h1>
                <p>
                  Types and tests can both be leveraged to improve code quality
                  in JavaScript applications. Unfortunately, both approaches
                  tend to increase initial development time - although they save
                  time and money in the long run. This lightning talk explains
                  why, given tight development deadlines, tests give a better
                  return on investment than types. Please note, this talk is NOT
                  intended to discredit types, TypeScript, or other similar
                  technologies.
                </p>
                <p>
                  Colin Ihrig is a member of the Node.js Technical Steering
                  Committee, a libuv collaborator, and a hapi.js core team
                  member. Colin is the author of Pro Node.js for Developers, and
                  co-author of Full Stack JavaScript Development with MEAN.
                  Colin is currently a software engineer at Joyent, focusing on
                  Node.js enterprise support.
                </p>
              </Column>
            </Row>

            <Row paddingTop id="nancy">
              <Column shrink>
                <Speaker href="http://nancydu.me/" center>
                  <img src={nancy} />
                </Speaker>
              </Column>
              <Column>
                <a href="http://nancydu.me/" className="noUnderline">
                  <SpeakerName large company="Rangle.io">
                    Nancy Du
                  </SpeakerName>
                </a>
                <h1>Integration Testing with Cypress</h1>
                <p>
                  Automated UI testing is an important part of ensuring quality,
                  which establishes confidence and help you ship faster.
                  Unfortunately, writing e2e tests with protractor is difficult,
                  hard to scale and will quickly become a maintenance nightmare.
                  You can reduce your reliance on e2e tests by adding
                  integration tests to your strategy. In this talk we will take
                  a look at how Cypress can help you implement a strong
                  integration strategy that improves efficiency, quality and
                  promotes collaboration across your team.
                </p>
                <p>
                  Nancy is a software developer working at Rangle.io. She is
                  passionate about quality and spends her free time contributing
                  to the open source community for testing tools.
                </p>
              </Column>
            </Row>

            <Row paddingTop id="michael">
              <Column shrink>
                <Speaker href="https://twitter.com/mshilman" center>
                  <img src={michael} />
                </Speaker>
              </Column>
              <Column>
                <a href="https://twitter.com/mshilman" className="noUnderline">
                  <SpeakerName large company="Chroma, Storybook">
                    Michael Shilman
                  </SpeakerName>
                </a>
                <h1>Component-Driven Development &amp; Visual Test Coverage</h1>
                <p>
                  Component frameworks like React/Vue/Angular have transformed
                  the way user interfaces are designed, developed, and tested.
                  Component-driven development (CDD) is a simple way to build,
                  document, and maintain complex user interfaces for
                  interdisciplinary teams. When combined with visual regression
                  tests, CDD gives you awesome test coverage with almost zero
                  additional work. In this talk, Michael will present a new tool
                  to quantify and understand test coverage from visual tests,
                  and show how this “testing-centric” way of evaluating your
                  application can lead to better CDD. He will also compare and
                  contrast this “component-centric” approach with other testing
                  approaches and explain its strengths and limitations.
                </p>
                <p>
                  Michael maintains Storybook, the leading UI component workshop
                  used by Airbnb, Lyft, Uber, Microsoft, IBM, Github, Atlassian,
                  and thousands of other teams. He also develops Chromatic, the
                  world’s most stable, scalable, and performant cross-browser UI
                  component testing service.
                </p>
              </Column>
            </Row>

            <Row paddingTop id="josh">
              <Column shrink>
                <Speaker href="https://twitter.com/CodingItWrong" center>
                  <img src={josh} />
                </Speaker>
              </Column>
              <Column>
                <a
                  href="https://twitter.com/CodingItWrong"
                  className="noUnderline"
                >
                  <SpeakerName large company="Big Nerd Ranch">
                    Josh Justice
                  </SpeakerName>
                </a>
                <h1>Old solutions to new testing problems</h1>
                <p>
                  JavaScript test tooling has advanced a lot in the last few
                  years, but tooling can’t solve everything—we still have
                  decisions to make about how to optimally set up our tests. It
                  would be great if we could learn from experienced testers who
                  came before us, but it can be difficult to follow writing
                  about testing in a programming language we aren’t familiar
                  with.
                </p>
                <p>
                  Luckily, there’s one book in particular that has a lot of
                  language-agnostic testing wisdom to share:{' '}
                  <i>xUnit Test Patterns</i>. We’ll walk through some of the
                  “test smells” it describes and see examples of how they
                  commonly arise in JavaScript, then we’ll apply the principles
                  from the book to solve these problems. You’ll walk away from
                  this session with more tools in your tool belt to solve
                  testing problems, and clearer language to talk about the tools
                  you already have.
                </p>
                <p>
                  Josh Justice has worked as a developer for 14 years across
                  backend, frontend, and mobile platforms. He writes about
                  testing and test-driven development on{' '}
                  <a href="https://learntdd.in">https://learntdd.in</a> and{' '}
                  <a href="https://reactnativetesting.io">
                    https://reactnativetesting.io
                  </a>
                  , and speaks about them at meetups and conferences.
                </p>
              </Column>
            </Row>
            <Row paddingTop id="carolina">
              <Column shrink>
                <Speaker href="http://www.carolinapinzon.com/" center>
                  <img src={carolina} />
                </Speaker>
              </Column>
              <Column>
                <a
                  href="http://www.carolinapinzon.com/"
                  className="noUnderline"
                >
                  <SpeakerName large company="Dapper Labs">
                    Carolina Pinzon
                  </SpeakerName>
                </a>
                <h1>Robust tests for unconventional environments</h1>
                <p>
                  Writing robust tests for Dapper, a smart contract Ethereum
                  wallet built as a Chrome extension, was a difficult task
                  because of its unusual architecture. Since their users trust
                  them with their valuable tokens and assets, tests were
                  necessary for their team to deliver high-quality code.
                  However, they were almost discouraged from implementing those
                  tests because of the unique blockers they faced while setting
                  up their testing environment. In this talk, Carolina will
                  highlight the nuanced patterns and approaches they took to
                  facing those challenges, in the hopes of giving other teams
                  the tools to overcome obstacles when adding tests.
                </p>
                <p>Some of the topics that this talk will cover are:</p>
                <ul>
                  <li>
                    How to build integration tests for an application that
                    communicates with other webpages using a background script
                  </li>
                  <li>
                    How to test interactions with a decentralized network in
                    which transactions can take a couple of minutes to process
                  </li>
                  <li>
                    How to check security vulnerabilities when dealing with
                    client-side generated keys"
                  </li>
                </ul>
                <p>
                  Carolina is a Colombian Software and Electronics Engineer
                  living in Vancouver. She enjoys both low and high-level
                  programming languages, but her passion is Frontend
                  Engineering. She works at Dapper Labs, where she helped build
                  CryptoKitties, the most popular blockchain game in history.
                </p>
                <p>
                  She is currently enjoying new challenges developing Dapper, a
                  smart contract Ethereum wallet built as a Chrome extension. On
                  this project, She has seen first-hand how reliable tests give
                  her team confidence to write high-quality code and deliver
                  features faster.
                </p>
              </Column>
            </Row>
            <Row paddingTop id="isaac">
              <Column shrink>
                <Speaker href="https://twitter.com/izs" center>
                  <img src={isaac} />
                </Speaker>
              </Column>
              <Column>
                <a href="https://twitter.com/izs" className="noUnderline">
                  <SpeakerName large company="npm">
                    Isaac Z. Schlueter
                  </SpeakerName>
                </a>
                <h1>
                  99% is not enough - full test coverage and why it's awesome
                </h1>
                <p>
                  This is a talk about the benefits of rocking 100% test
                  coverage (all lines, branches, statements, and functions).
                  Isaac will address the arguments against doing this, the
                  limits of the benefits, why it's worth the costs, how to make
                  it stick, how to get even further with a coverage map, and how
                  it improves your approach to writing code. And hopefully he
                  will upset a small enough portion of the audience that he can
                  get out with his head still attached.
                </p>
                <p>
                  Isaac is an accomplished JavaScript noisemaker. You may know
                  him from such projects as npm, Node.js, and lots of other
                  junk. He's here at AssertJS in his capacity as the author and
                  lead maintainer of node-tap, JavaScript's second best and
                  third most popular open source test framework.
                </p>
              </Column>
            </Row>

            <Row paddingTop id="ryan">
              <Column shrink>
                <Speaker href="https://twitter.com/ryan_marsh" center>
                  <img src={ryan} />
                </Speaker>
              </Column>
              <Column>
                <a
                  href="https://twitter.com/ryan_marsh"
                  className="noUnderline"
                >
                  <SpeakerName large company="thestack.io">
                    Ryan Marsh
                  </SpeakerName>
                </a>
                <h1>
                  This clever workshop will convert your team into TDD
                  enthusiasts
                </h1>
                <p>
                  TDD enthusiasts want others to get the religion but many
                  developers find TDD awkward. Ryan has a clever workshop
                  technique to flip whole teams to *preferring* TDD. It's fun
                  and it uses some pavlovian conditioning. In this talk he will
                  break it down so attendees can run the workshop for their
                  teams.
                </p>
                <p>
                  Ryan is a software development coach at Cucumber and
                  TheStack.io. Ryan helps teams increase delivery pace and
                  quality with test practices and DevOps.
                </p>
              </Column>
            </Row>

            <Row paddingTop id="clare">
              <Column shrink>
                <Speaker href="https://twitter.com/clarmso" center>
                  <img src={clare} />
                </Speaker>
              </Column>
              <Column>
                <a href="https://twitter.com/clarmso" className="noUnderline">
                  <SpeakerName large company="OTTO Motors">
                    Clare So
                  </SpeakerName>
                </a>
                <h1>Pros and cons of UI testing tools</h1>
                <p>
                  Selenium, Puppeteer and Cypress can be used for front-end web
                  application testing. They can automate the keystrokes and
                  mouse events. This lightning talk provides a quick overview of
                  the pros and cons of each tool.
                </p>
                <p>
                  Clare is a UI test developer for the test team at OTTO Motors,
                  a division of Clearpath Robotics. She has done both front end
                  and backend functional testing for web applications in the
                  past few years.
                </p>
              </Column>
            </Row>
          </Wrapper>
        </Section>

        <Section noPaddingTop center>
          <Wrapper>
            <Button href="https://www.youtube.com/playlist?list=PLZ66c9_z3umNSrKSb5cmpxdXZcIPNvKGw">
              Watch the talks from 2018
            </Button>
          </Wrapper>
        </Section>

        <Footer />
      </Container>
    );
  }
}

export default ProposeTalk;
