import React from 'react';
import styled from 'styled-components';

const SpeakerName = ({ large, company, children }) => (
  <SpeakerNameContainer large={large}>
    <Company>{company}</Company>
    <span>{children}</span>
    <Borders />
  </SpeakerNameContainer>
);

const Company = styled.div`
  text-decoration: none;
  color: yellow;
  font-weight: bold;
  display: inline;
  float: right;
  padding-right: 24px;
`;

const Borders = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  ::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    right: -8px;
    top: -8px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transition: all 1200ms ease;
  }
  ::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: -8px;
    bottom: -8px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    transition: all 1200ms ease;
  }
`;

const SpeakerNameContainer = styled.div`
  position: relative;
  font-family: Orbitron;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white !important;
  border-bottom: none !important;
  background-color: #412b60;
  display: block;
  margin: 16px 8px;
  padding: 4px 6px 1px;
  transition: all 200ms ease;
  cursor: pointer;
  ${props =>
    props.large &&
    `
      font-size: 24px;
      line-height: 40px;
      text-align: left;
      padding-left: 24px;
      margin-bottom: 24px;
    `}
  ::before {
    transition: all 600ms ease;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: -8px;
    top: -8px;
    border-top: 2px solid white;
    border-left: 2px solid white;
  }
  ::after {
    transition: all 600ms ease;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    right: -8px;
    bottom: -8px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }
`;

export default SpeakerName;
